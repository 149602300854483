import { default as aboutghnrJl35FfMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as events99CMHujHxbMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93zuhCkYgztQMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sample5DtxyQWEFWMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsurJgGFpoHlMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93vKlxLBNeTfMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as view88U1Ehl6GRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexcUm0cttrKTMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93MESzb891aRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexweNgN6LDJFMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93jQgaN17KneMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexrfBfj8ZzPRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsfi77kha1ozMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings3RCSzsI8RwMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsoqpCkMNlcgMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93CyaTBchxAiMeta } from "/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexcX4mUJ8HZgMeta } from "/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmOpxw8q7taPMeta } from "/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirm5Tj4wL3uPyMeta } from "/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendar5hmUxcpuRNMeta } from "/tmp/build_52b9715f/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatSrKgs52nGyMeta } from "/tmp/build_52b9715f/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutSCEgUYTCWdMeta } from "/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedYowkWwBDa0Meta } from "/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersDAEEnKQJYYMeta } from "/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93sv1U8SxdFQMeta } from "/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45edit3UvxcSPEBsMeta } from "/tmp/build_52b9715f/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93DlLRVf1JdbMeta } from "/tmp/build_52b9715f/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as community2FotTdnrx0Meta } from "/tmp/build_52b9715f/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93tktnapZ10SMeta } from "/tmp/build_52b9715f/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93Z5KfjGwN35Meta } from "/tmp/build_52b9715f/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardfsUfbW4dt0Meta } from "/tmp/build_52b9715f/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexy8fHypQGHTMeta } from "/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutP1NI3BmS5hMeta } from "/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productgLI6iqvmJIMeta } from "/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsfpesXLH2nSMeta } from "/tmp/build_52b9715f/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93jstcz74EGhMeta } from "/tmp/build_52b9715f/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexCBfT7Zmpt9Meta } from "/tmp/build_52b9715f/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93yZNcqNYfGdMeta } from "/tmp/build_52b9715f/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93R3ILY60247Meta } from "/tmp/build_52b9715f/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as index4xyryMUKDeMeta } from "/tmp/build_52b9715f/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93CAygdCrB9OMeta } from "/tmp/build_52b9715f/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinKCj6Vm2Ae2Meta } from "/tmp/build_52b9715f/pages/[studio_url]/join.vue?macro=true";
import { default as loginWN7Uf5QAQ8Meta } from "/tmp/build_52b9715f/pages/[studio_url]/login.vue?macro=true";
import { default as logout3hAGliPuflMeta } from "/tmp/build_52b9715f/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93hPACFG84cyMeta } from "/tmp/build_52b9715f/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93IqGhGQbKNBMeta } from "/tmp/build_52b9715f/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93tPUDFL3qFbMeta } from "/tmp/build_52b9715f/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritessfaJLb8xptMeta } from "/tmp/build_52b9715f/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosoL5z54iRy3Meta } from "/tmp/build_52b9715f/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinZAnShGGSgMMeta } from "/tmp/build_52b9715f/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93P8B0cx8HPbMeta } from "/tmp/build_52b9715f/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexx31vwA76gEMeta } from "/tmp/build_52b9715f/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexW7Y0ASo8kRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutvfQjZHZuFVMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentH8roKBmK4hMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as eventsMPvBKt9YnIMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_935RkwWbRSQBMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplevSrECEZBrrMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45products27i6MCbXPEMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93exJ1ksdf7ZMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewQ7Vpv6RLFtMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexAQ6ZcW4doWMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93kflUBSKJzzMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as index4lWFPSNOTeMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93fIDVuziDiRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexYN6lJsCl2RMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsqeNOJLu67vMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsI1YIQqkreuMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsTMbtBFNMofMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93VwheaUz1ZfMeta } from "/tmp/build_52b9715f/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexPv5IIkWJ86Meta } from "/tmp/build_52b9715f/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexi5f2TOzndDMeta } from "/tmp/build_52b9715f/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchaseslWWFYISizRMeta } from "/tmp/build_52b9715f/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsJEX9MBKlpyMeta } from "/tmp/build_52b9715f/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93p3xg0FE0beMeta } from "/tmp/build_52b9715f/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93tQfAl1H0JKMeta } from "/tmp/build_52b9715f/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as reset2zAmqATaGxMeta } from "/tmp/build_52b9715f/pages/[studio_url]/reset.vue?macro=true";
import { default as shopTSoqTIFtgVMeta } from "/tmp/build_52b9715f/pages/[studio_url]/shop.vue?macro=true";
import { default as signup0Xxjuolq8GMeta } from "/tmp/build_52b9715f/pages/[studio_url]/signup.vue?macro=true";
import { default as liveOD29twQmqfMeta } from "/tmp/build_52b9715f/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsIX3aEVVPH2Meta } from "/tmp/build_52b9715f/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93cUYlNJ2iBdMeta } from "/tmp/build_52b9715f/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactF4cQLmGTroMeta } from "/tmp/build_52b9715f/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullniKIvj1yPTMeta } from "/tmp/build_52b9715f/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93CyaTBchxAiMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93CyaTBchxAiMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93CyaTBchxAiMeta || {},
    alias: _91product_id_93CyaTBchxAiMeta?.alias || [],
    redirect: _91product_id_93CyaTBchxAiMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutghnrJl35FfMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutghnrJl35FfMeta?.path ?? "about",
    meta: aboutghnrJl35FfMeta || {},
    alias: aboutghnrJl35FfMeta?.alias || [],
    redirect: aboutghnrJl35FfMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: events99CMHujHxbMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: events99CMHujHxbMeta?.path ?? "events",
    meta: events99CMHujHxbMeta || {},
    alias: events99CMHujHxbMeta?.alias || [],
    redirect: events99CMHujHxbMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93zuhCkYgztQMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93zuhCkYgztQMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93zuhCkYgztQMeta || {},
    alias: _91media_id_93zuhCkYgztQMeta?.alias || [],
    redirect: _91media_id_93zuhCkYgztQMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sample5DtxyQWEFWMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sample5DtxyQWEFWMeta?.path ?? "free-sample",
    meta: free_45sample5DtxyQWEFWMeta || {},
    alias: free_45sample5DtxyQWEFWMeta?.alias || [],
    redirect: free_45sample5DtxyQWEFWMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsurJgGFpoHlMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsurJgGFpoHlMeta?.path ?? "included-products",
    meta: included_45productsurJgGFpoHlMeta || {},
    alias: included_45productsurJgGFpoHlMeta?.alias || [],
    redirect: included_45productsurJgGFpoHlMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vKlxLBNeTfMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93vKlxLBNeTfMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93vKlxLBNeTfMeta || {},
    alias: _91media_id_93vKlxLBNeTfMeta?.alias || [],
    redirect: _91media_id_93vKlxLBNeTfMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: view88U1Ehl6GRMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: view88U1Ehl6GRMeta?.path ?? "lessons/:lesson_id()/view",
    meta: view88U1Ehl6GRMeta || {},
    alias: view88U1Ehl6GRMeta?.alias || [],
    redirect: view88U1Ehl6GRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexcUm0cttrKTMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexcUm0cttrKTMeta?.path ?? "lessons",
    meta: indexcUm0cttrKTMeta || {},
    alias: indexcUm0cttrKTMeta?.alias || [],
    redirect: indexcUm0cttrKTMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93MESzb891aRMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93MESzb891aRMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93MESzb891aRMeta || {},
    alias: _91media_id_93MESzb891aRMeta?.alias || [],
    redirect: _91media_id_93MESzb891aRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexweNgN6LDJFMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexweNgN6LDJFMeta?.path ?? "playlists/:playlist_id()",
    meta: indexweNgN6LDJFMeta || {},
    alias: indexweNgN6LDJFMeta?.alias || [],
    redirect: indexweNgN6LDJFMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93jQgaN17KneMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93jQgaN17KneMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93jQgaN17KneMeta || {},
    alias: _91media_id_93jQgaN17KneMeta?.alias || [],
    redirect: _91media_id_93jQgaN17KneMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexrfBfj8ZzPRMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexrfBfj8ZzPRMeta?.path ?? "playlists",
    meta: indexrfBfj8ZzPRMeta || {},
    alias: indexrfBfj8ZzPRMeta?.alias || [],
    redirect: indexrfBfj8ZzPRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsfi77kha1ozMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsfi77kha1ozMeta?.path ?? "private-sessions",
    meta: private_45sessionsfi77kha1ozMeta || {},
    alias: private_45sessionsfi77kha1ozMeta?.alias || [],
    redirect: private_45sessionsfi77kha1ozMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings3RCSzsI8RwMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings3RCSzsI8RwMeta?.path ?? "recordings",
    meta: recordings3RCSzsI8RwMeta || {},
    alias: recordings3RCSzsI8RwMeta?.alias || [],
    redirect: recordings3RCSzsI8RwMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsoqpCkMNlcgMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsoqpCkMNlcgMeta?.path ?? "reviews",
    meta: reviewsoqpCkMNlcgMeta || {},
    alias: reviewsoqpCkMNlcgMeta?.alias || [],
    redirect: reviewsoqpCkMNlcgMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexcX4mUJ8HZgMeta?.name ?? "buy-product-product_id",
    path: indexcX4mUJ8HZgMeta?.path ?? "/buy/product/:product_id()",
    meta: indexcX4mUJ8HZgMeta || {},
    alias: indexcX4mUJ8HZgMeta?.alias || [],
    redirect: indexcX4mUJ8HZgMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmOpxw8q7taPMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmOpxw8q7taPMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmOpxw8q7taPMeta || {},
    alias: confirmOpxw8q7taPMeta?.alias || [],
    redirect: confirmOpxw8q7taPMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirm5Tj4wL3uPyMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirm5Tj4wL3uPyMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirm5Tj4wL3uPyMeta || {},
    alias: confirm5Tj4wL3uPyMeta?.alias || [],
    redirect: confirm5Tj4wL3uPyMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendar5hmUxcpuRNMeta?.name ?? "calendar",
    path: calendar5hmUxcpuRNMeta?.path ?? "/calendar",
    meta: calendar5hmUxcpuRNMeta || {},
    alias: calendar5hmUxcpuRNMeta?.alias || [],
    redirect: calendar5hmUxcpuRNMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatSrKgs52nGyMeta?.name ?? "chat",
    path: chatSrKgs52nGyMeta?.path ?? "/chat",
    meta: chatSrKgs52nGyMeta || {},
    alias: chatSrKgs52nGyMeta?.alias || [],
    redirect: chatSrKgs52nGyMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93sv1U8SxdFQMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93sv1U8SxdFQMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93sv1U8SxdFQMeta || {},
    alias: _91group_id_93sv1U8SxdFQMeta?.alias || [],
    redirect: _91group_id_93sv1U8SxdFQMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutSCEgUYTCWdMeta?.name ?? "community-groups-group_id-about",
    path: aboutSCEgUYTCWdMeta?.path ?? "about",
    meta: aboutSCEgUYTCWdMeta || {},
    alias: aboutSCEgUYTCWdMeta?.alias || [],
    redirect: aboutSCEgUYTCWdMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedYowkWwBDa0Meta?.name ?? "community-groups-group_id-feed",
    path: feedYowkWwBDa0Meta?.path ?? "feed",
    meta: feedYowkWwBDa0Meta || {},
    alias: feedYowkWwBDa0Meta?.alias || [],
    redirect: feedYowkWwBDa0Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersDAEEnKQJYYMeta?.name ?? "community-groups-group_id-members",
    path: membersDAEEnKQJYYMeta?.path ?? "members",
    meta: membersDAEEnKQJYYMeta || {},
    alias: membersDAEEnKQJYYMeta?.alias || [],
    redirect: membersDAEEnKQJYYMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45edit3UvxcSPEBsMeta?.name ?? "community-profile-edit",
    path: community_45profile_45edit3UvxcSPEBsMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45edit3UvxcSPEBsMeta || {},
    alias: community_45profile_45edit3UvxcSPEBsMeta?.alias || [],
    redirect: community_45profile_45edit3UvxcSPEBsMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DlLRVf1JdbMeta?.name ?? "community-profile-id",
    path: _91id_93DlLRVf1JdbMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93DlLRVf1JdbMeta || {},
    alias: _91id_93DlLRVf1JdbMeta?.alias || [],
    redirect: _91id_93DlLRVf1JdbMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: community2FotTdnrx0Meta?.name ?? "community",
    path: community2FotTdnrx0Meta?.path ?? "/community",
    meta: community2FotTdnrx0Meta || {},
    alias: community2FotTdnrx0Meta?.alias || [],
    redirect: community2FotTdnrx0Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93tktnapZ10SMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93tktnapZ10SMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93tktnapZ10SMeta || {},
    alias: _91uuid_93tktnapZ10SMeta?.alias || [],
    redirect: _91uuid_93tktnapZ10SMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93Z5KfjGwN35Meta?.name ?? "contracts-unique_id",
    path: _91unique_id_93Z5KfjGwN35Meta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93Z5KfjGwN35Meta || {},
    alias: _91unique_id_93Z5KfjGwN35Meta?.alias || [],
    redirect: _91unique_id_93Z5KfjGwN35Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardfsUfbW4dt0Meta?.name ?? "dashboard",
    path: dashboardfsUfbW4dt0Meta?.path ?? "/dashboard",
    meta: dashboardfsUfbW4dt0Meta || {},
    alias: dashboardfsUfbW4dt0Meta?.alias || [],
    redirect: dashboardfsUfbW4dt0Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexy8fHypQGHTMeta?.name ?? "event-details-event_id",
    path: indexy8fHypQGHTMeta?.path ?? "/event/details/:event_id()",
    meta: indexy8fHypQGHTMeta || {},
    alias: indexy8fHypQGHTMeta?.alias || [],
    redirect: indexy8fHypQGHTMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutP1NI3BmS5hMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutP1NI3BmS5hMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutP1NI3BmS5hMeta || {},
    alias: checkoutP1NI3BmS5hMeta?.alias || [],
    redirect: checkoutP1NI3BmS5hMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productgLI6iqvmJIMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productgLI6iqvmJIMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productgLI6iqvmJIMeta || {},
    alias: select_45productgLI6iqvmJIMeta?.alias || [],
    redirect: select_45productgLI6iqvmJIMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsfpesXLH2nSMeta?.name ?? "events",
    path: eventsfpesXLH2nSMeta?.path ?? "/events",
    meta: eventsfpesXLH2nSMeta || {},
    alias: eventsfpesXLH2nSMeta?.alias || [],
    redirect: eventsfpesXLH2nSMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93jstcz74EGhMeta?.name ?? "gifts-uuid",
    path: _91uuid_93jstcz74EGhMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93jstcz74EGhMeta || {},
    alias: _91uuid_93jstcz74EGhMeta?.alias || [],
    redirect: _91uuid_93jstcz74EGhMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexCBfT7Zmpt9Meta?.name ?? "gifts",
    path: indexCBfT7Zmpt9Meta?.path ?? "/gifts",
    meta: indexCBfT7Zmpt9Meta || {},
    alias: indexCBfT7Zmpt9Meta?.alias || [],
    redirect: indexCBfT7Zmpt9Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93yZNcqNYfGdMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93yZNcqNYfGdMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93yZNcqNYfGdMeta || {},
    alias: _91invitation_id_93yZNcqNYfGdMeta?.alias || [],
    redirect: _91invitation_id_93yZNcqNYfGdMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93R3ILY60247Meta?.name ?? "gifts-product-product_id",
    path: _91product_id_93R3ILY60247Meta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93R3ILY60247Meta || {},
    alias: _91product_id_93R3ILY60247Meta?.alias || [],
    redirect: _91product_id_93R3ILY60247Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: index4xyryMUKDeMeta?.name ?? "index",
    path: index4xyryMUKDeMeta?.path ?? "/",
    meta: index4xyryMUKDeMeta || {},
    alias: index4xyryMUKDeMeta?.alias || [],
    redirect: index4xyryMUKDeMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93CAygdCrB9OMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93CAygdCrB9OMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93CAygdCrB9OMeta || {},
    alias: _91invitation_unique_id_93CAygdCrB9OMeta?.alias || [],
    redirect: _91invitation_unique_id_93CAygdCrB9OMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinKCj6Vm2Ae2Meta?.name ?? "join",
    path: joinKCj6Vm2Ae2Meta?.path ?? "/join",
    meta: joinKCj6Vm2Ae2Meta || {},
    alias: joinKCj6Vm2Ae2Meta?.alias || [],
    redirect: joinKCj6Vm2Ae2Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginWN7Uf5QAQ8Meta?.name ?? "login",
    path: loginWN7Uf5QAQ8Meta?.path ?? "/login",
    meta: loginWN7Uf5QAQ8Meta || {},
    alias: loginWN7Uf5QAQ8Meta?.alias || [],
    redirect: loginWN7Uf5QAQ8Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logout3hAGliPuflMeta?.name ?? "logout",
    path: logout3hAGliPuflMeta?.path ?? "/logout",
    meta: logout3hAGliPuflMeta || {},
    alias: logout3hAGliPuflMeta?.alias || [],
    redirect: logout3hAGliPuflMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93hPACFG84cyMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93hPACFG84cyMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93hPACFG84cyMeta || {},
    alias: _91meeting_type_slug_93hPACFG84cyMeta?.alias || [],
    redirect: _91meeting_type_slug_93hPACFG84cyMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93IqGhGQbKNBMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93IqGhGQbKNBMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93IqGhGQbKNBMeta || {},
    alias: _91meeting_unique_id_93IqGhGQbKNBMeta?.alias || [],
    redirect: _91meeting_unique_id_93IqGhGQbKNBMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93tPUDFL3qFbMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93tPUDFL3qFbMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93tPUDFL3qFbMeta || {},
    alias: _91meeting_unique_id_93tPUDFL3qFbMeta?.alias || [],
    redirect: _91meeting_unique_id_93tPUDFL3qFbMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritessfaJLb8xptMeta?.name ?? "my-favorites",
    path: my_45favoritessfaJLb8xptMeta?.path ?? "/my-favorites",
    meta: my_45favoritessfaJLb8xptMeta || {},
    alias: my_45favoritessfaJLb8xptMeta?.alias || [],
    redirect: my_45favoritessfaJLb8xptMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosoL5z54iRy3Meta?.name ?? "my-studios",
    path: my_45studiosoL5z54iRy3Meta?.path ?? "/my-studios",
    meta: my_45studiosoL5z54iRy3Meta || {},
    alias: my_45studiosoL5z54iRy3Meta?.alias || [],
    redirect: my_45studiosoL5z54iRy3Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinZAnShGGSgMMeta?.name ?? "optin",
    path: optinZAnShGGSgMMeta?.path ?? "/optin",
    meta: optinZAnShGGSgMMeta || {},
    alias: optinZAnShGGSgMMeta?.alias || [],
    redirect: optinZAnShGGSgMMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93P8B0cx8HPbMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93P8B0cx8HPbMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93P8B0cx8HPbMeta || {},
    alias: _91episode_id_93P8B0cx8HPbMeta?.alias || [],
    redirect: _91episode_id_93P8B0cx8HPbMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexx31vwA76gEMeta?.name ?? "podcasts-podcast_id",
    path: indexx31vwA76gEMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexx31vwA76gEMeta || {},
    alias: indexx31vwA76gEMeta?.alias || [],
    redirect: indexx31vwA76gEMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW7Y0ASo8kRMeta?.name ?? "podcasts",
    path: indexW7Y0ASo8kRMeta?.path ?? "/podcasts",
    meta: indexW7Y0ASo8kRMeta || {},
    alias: indexW7Y0ASo8kRMeta?.alias || [],
    redirect: indexW7Y0ASo8kRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93VwheaUz1ZfMeta?.name ?? "product-product_id",
    path: _91product_id_93VwheaUz1ZfMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93VwheaUz1ZfMeta || {},
    alias: _91product_id_93VwheaUz1ZfMeta?.alias || [],
    redirect: _91product_id_93VwheaUz1ZfMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutvfQjZHZuFVMeta?.name ?? "product-product_id-about",
    path: aboutvfQjZHZuFVMeta?.path ?? "about",
    meta: aboutvfQjZHZuFVMeta || {},
    alias: aboutvfQjZHZuFVMeta?.alias || [],
    redirect: aboutvfQjZHZuFVMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentH8roKBmK4hMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentH8roKBmK4hMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentH8roKBmK4hMeta || {},
    alias: digital_45download_45contentH8roKBmK4hMeta?.alias || [],
    redirect: digital_45download_45contentH8roKBmK4hMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: eventsMPvBKt9YnIMeta?.name ?? "product-product_id-events",
    path: eventsMPvBKt9YnIMeta?.path ?? "events",
    meta: eventsMPvBKt9YnIMeta || {},
    alias: eventsMPvBKt9YnIMeta?.alias || [],
    redirect: eventsMPvBKt9YnIMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_935RkwWbRSQBMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_935RkwWbRSQBMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_935RkwWbRSQBMeta || {},
    alias: _91media_id_935RkwWbRSQBMeta?.alias || [],
    redirect: _91media_id_935RkwWbRSQBMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplevSrECEZBrrMeta?.name ?? "product-product_id-free-sample",
    path: free_45samplevSrECEZBrrMeta?.path ?? "free-sample",
    meta: free_45samplevSrECEZBrrMeta || {},
    alias: free_45samplevSrECEZBrrMeta?.alias || [],
    redirect: free_45samplevSrECEZBrrMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45products27i6MCbXPEMeta?.name ?? "product-product_id-included-products",
    path: included_45products27i6MCbXPEMeta?.path ?? "included-products",
    meta: included_45products27i6MCbXPEMeta || {},
    alias: included_45products27i6MCbXPEMeta?.alias || [],
    redirect: included_45products27i6MCbXPEMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93exJ1ksdf7ZMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93exJ1ksdf7ZMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93exJ1ksdf7ZMeta || {},
    alias: _91media_id_93exJ1ksdf7ZMeta?.alias || [],
    redirect: _91media_id_93exJ1ksdf7ZMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewQ7Vpv6RLFtMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewQ7Vpv6RLFtMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewQ7Vpv6RLFtMeta || {},
    alias: viewQ7Vpv6RLFtMeta?.alias || [],
    redirect: viewQ7Vpv6RLFtMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexAQ6ZcW4doWMeta?.name ?? "product-product_id-lessons",
    path: indexAQ6ZcW4doWMeta?.path ?? "lessons",
    meta: indexAQ6ZcW4doWMeta || {},
    alias: indexAQ6ZcW4doWMeta?.alias || [],
    redirect: indexAQ6ZcW4doWMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93kflUBSKJzzMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93kflUBSKJzzMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93kflUBSKJzzMeta || {},
    alias: _91media_id_93kflUBSKJzzMeta?.alias || [],
    redirect: _91media_id_93kflUBSKJzzMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index4lWFPSNOTeMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: index4lWFPSNOTeMeta?.path ?? "playlists/:playlist_id()",
    meta: index4lWFPSNOTeMeta || {},
    alias: index4lWFPSNOTeMeta?.alias || [],
    redirect: index4lWFPSNOTeMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93fIDVuziDiRMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93fIDVuziDiRMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93fIDVuziDiRMeta || {},
    alias: _91media_id_93fIDVuziDiRMeta?.alias || [],
    redirect: _91media_id_93fIDVuziDiRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexYN6lJsCl2RMeta?.name ?? "product-product_id-playlists",
    path: indexYN6lJsCl2RMeta?.path ?? "playlists",
    meta: indexYN6lJsCl2RMeta || {},
    alias: indexYN6lJsCl2RMeta?.alias || [],
    redirect: indexYN6lJsCl2RMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsqeNOJLu67vMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsqeNOJLu67vMeta?.path ?? "private-sessions",
    meta: private_45sessionsqeNOJLu67vMeta || {},
    alias: private_45sessionsqeNOJLu67vMeta?.alias || [],
    redirect: private_45sessionsqeNOJLu67vMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsI1YIQqkreuMeta?.name ?? "product-product_id-recordings",
    path: recordingsI1YIQqkreuMeta?.path ?? "recordings",
    meta: recordingsI1YIQqkreuMeta || {},
    alias: recordingsI1YIQqkreuMeta?.alias || [],
    redirect: recordingsI1YIQqkreuMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsTMbtBFNMofMeta?.name ?? "product-product_id-reviews",
    path: reviewsTMbtBFNMofMeta?.path ?? "reviews",
    meta: reviewsTMbtBFNMofMeta || {},
    alias: reviewsTMbtBFNMofMeta?.alias || [],
    redirect: reviewsTMbtBFNMofMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPv5IIkWJ86Meta?.name ?? "product",
    path: indexPv5IIkWJ86Meta?.path ?? "/product",
    meta: indexPv5IIkWJ86Meta || {},
    alias: indexPv5IIkWJ86Meta?.alias || [],
    redirect: indexPv5IIkWJ86Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5f2TOzndDMeta?.name ?? "profile",
    path: indexi5f2TOzndDMeta?.path ?? "/profile",
    meta: indexi5f2TOzndDMeta || {},
    alias: indexi5f2TOzndDMeta?.alias || [],
    redirect: indexi5f2TOzndDMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchaseslWWFYISizRMeta?.name ?? "profile-purchases",
    path: purchaseslWWFYISizRMeta?.path ?? "/profile/purchases",
    meta: purchaseslWWFYISizRMeta || {},
    alias: purchaseslWWFYISizRMeta?.alias || [],
    redirect: purchaseslWWFYISizRMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsJEX9MBKlpyMeta?.name ?? "profile-redemptions",
    path: redemptionsJEX9MBKlpyMeta?.path ?? "/profile/redemptions",
    meta: redemptionsJEX9MBKlpyMeta || {},
    alias: redemptionsJEX9MBKlpyMeta?.alias || [],
    redirect: redemptionsJEX9MBKlpyMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93p3xg0FE0beMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93p3xg0FE0beMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93p3xg0FE0beMeta || {},
    alias: _91quiz_id_93p3xg0FE0beMeta?.alias || [],
    redirect: _91quiz_id_93p3xg0FE0beMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93tQfAl1H0JKMeta?.name ?? "reset-password-uid-token",
    path: _91token_93tQfAl1H0JKMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93tQfAl1H0JKMeta || {},
    alias: _91token_93tQfAl1H0JKMeta?.alias || [],
    redirect: _91token_93tQfAl1H0JKMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: reset2zAmqATaGxMeta?.name ?? "reset",
    path: reset2zAmqATaGxMeta?.path ?? "/reset",
    meta: reset2zAmqATaGxMeta || {},
    alias: reset2zAmqATaGxMeta?.alias || [],
    redirect: reset2zAmqATaGxMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopTSoqTIFtgVMeta?.name ?? "shop",
    path: shopTSoqTIFtgVMeta?.path ?? "/shop",
    meta: shopTSoqTIFtgVMeta || {},
    alias: shopTSoqTIFtgVMeta?.alias || [],
    redirect: shopTSoqTIFtgVMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signup0Xxjuolq8GMeta?.name ?? "signup",
    path: signup0Xxjuolq8GMeta?.path ?? "/signup",
    meta: signup0Xxjuolq8GMeta || {},
    alias: signup0Xxjuolq8GMeta?.alias || [],
    redirect: signup0Xxjuolq8GMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveOD29twQmqfMeta?.name ?? "streaming-event_id-live",
    path: liveOD29twQmqfMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveOD29twQmqfMeta || {},
    alias: liveOD29twQmqfMeta?.alias || [],
    redirect: liveOD29twQmqfMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsIX3aEVVPH2Meta?.name ?? "tips",
    path: tipsIX3aEVVPH2Meta?.path ?? "/tips",
    meta: tipsIX3aEVVPH2Meta || {},
    alias: tipsIX3aEVVPH2Meta?.alias || [],
    redirect: tipsIX3aEVVPH2Meta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93cUYlNJ2iBdMeta?.name ?? "waiver-uuid",
    path: _91uuid_93cUYlNJ2iBdMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93cUYlNJ2iBdMeta || {},
    alias: _91uuid_93cUYlNJ2iBdMeta?.alias || [],
    redirect: _91uuid_93cUYlNJ2iBdMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactF4cQLmGTroMeta?.name ?? "widgets-calendars-compact",
    path: compactF4cQLmGTroMeta?.path ?? "/widgets/calendars/compact",
    meta: compactF4cQLmGTroMeta || {},
    alias: compactF4cQLmGTroMeta?.alias || [],
    redirect: compactF4cQLmGTroMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullniKIvj1yPTMeta?.name ?? "widgets-calendars-full",
    path: fullniKIvj1yPTMeta?.path ?? "/widgets/calendars/full",
    meta: fullniKIvj1yPTMeta || {},
    alias: fullniKIvj1yPTMeta?.alias || [],
    redirect: fullniKIvj1yPTMeta?.redirect,
    component: () => import("/tmp/build_52b9715f/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]